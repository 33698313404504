
import * as __star__ from '@clayui/link';

const {
default: __default__,
ClayLinkContext,
__esModule
} = __star__;

export {
ClayLinkContext,
__esModule
};
export default __default__;
